import React, { useState } from 'react';
import placeholder from '../../assets/images/bmg-placeholder-card-v2.jpg'

interface Props {
    nft: any,
    isDebug: boolean
}

function Card(props: Props) {
    const [hasError, setHasError]: any = useState([])

    return (
        <div className='mintcard-wrap'>
            <div className={`alert-img ${hasError || props.isDebug ? 'placeholder' : ''}`}>
                {props.nft.image && !props.isDebug
                    ? hasError[props.nft.image]
                        // eslint-disable-next-line jsx-a11y/alt-text
                        ? <div><img src={props.nft.image} onError={e => { setHasError([props.nft.image]) }} /></div>
                        // eslint-disable-next-line jsx-a11y/alt-text
                        : <img src={props.nft.image} onError={e => { setHasError(true) }} />
                    :
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img src={placeholder} onError={e => { setHasError(true) }} />
                }
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                {props.isDebug && <img src={placeholder} />}
            </div>
        </div>
    )
}

export default Card
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Menu from '../../components/Menu/Menu'
import NFTShowcase from '../../components/NFTShowcase/NFTShowcase'
import TextDialog from '../../components/TextDialog/TextDialog'
import LoaderDialog from '../../components/Loader/Loader'
import moment from 'moment'

import carnagelogo from '../../assets/images/bmg-carnage-logo.svg'
import iconOpenSea from '../../assets/images/open-sea-icon.svg'
import iconRarible from '../../assets/images/rarible-icon.svg'
import iconEtherium from '../../assets/images/ethereum-icon.svg'
import iconEtheriumlight from '../../assets/images/ethereum-icon-light.svg'
import card1 from '../../assets/images/cards/goblin.jpg'
import card2 from '../../assets/images/cards/orcking-worn.jpg'
import card3 from '../../assets/images/cards/assasin.jpg'
import card4 from '../../assets/images/cards/undead-berserker-misprint.jpg'
import card5 from '../../assets/images/cards/skeleton-mage-misprint.jpg'

import { getToken, originsContract } from '../../utils/contracts'
import {
  BN,
  divide,
  equalTo,
  greaterThan,
  greaterThanOrEqual,
  multiply,
} from '../../helpers/bignumber'
import { useWallet } from 'use-wallet'
import { sendTransaction, web3 } from '../../utils/web3'
import { getAccountTransactionsBasedOnContract } from '../../helpers/calls'

function Home() {
  const START_MINT_TIME = '2021-08-31 18:00:00';
  const [NFTShowcaseDebug] = useState(false)

  const { ethereum }: { ethereum: any } = useWallet()
  const { account } = useWallet()
  const [value, setValue] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [totalSupply, setTotalSupply] = useState(0)
  const [maxTotalSupply, setMaxTotalSupply] = useState(0)
  const [pricePerToken, setPricePerToken] = useState(BN(0).toString())
  const [maxTokensPerTransaction, setMaxTokensPerTransaction] = useState(
    BN(10).toString(),
  )
  const buyAmount = useMemo(
    () => multiply(multiply(value, pricePerToken), 1e18),
    [pricePerToken, value],
  )
  const showAmount = useMemo(
    () =>
      divide(buyAmount, 1e18).toString() !== 'NaN'
        ? divide(buyAmount, 1e18).toString()
        : '0',
    [buyAmount],
  )

  const [allTokensAreBought, setAllTokensAreBought] = useState(false)
  const [regenDialogState, setRegenDialogState] = useState(false)
  const [textDialogState, setTextDialogState] = useState(false)
  const [loaderDialogState, setLoaderDialogState] = useState(false)
  const [loadingText, setLoadingText] = useState(['', ''])
  const [boughtNFTS, setBoughtNFTS]: any = useState([])
  const [showcaseNFTS, setShowcaseNFTS]: any = useState([])
  const [showcaseNFTSIsLoading, setShowcaseNFTSIsLoading] = useState(false)
  const [isViewingCollection, setIsViewingCollection] = useState(false)
  const [isMinting, setIsMinting] = useState(false)
  const [canViewCollection, setCanViewCollection] = useState(false)
  const [mintBtnDisabled, setMintBtnDisabled] = useState(true)
  const [mintIntervalStarted, setMintIntervalStarted] = useState(false);

  /**
   * @dev Get the current price for 1 NFT
   */
  const getPricePerNFT = async () => {
    const priceInWei = await originsContract.methods.PRICE().call()
    const priceInEth = divide(priceInWei, 1e18)
    setPricePerToken(BN(priceInEth).toString())
  }

  /**
   * @dev Get the MAX tokens per transaction you can MINT
   */
  const getMaxTokensPerTX = async () => {
    const max = await originsContract.methods.MAX_PER_TX().call()
    setMaxTokensPerTransaction(max)
  }

  /**
   * @dev Makes a transaction to mint a NFT
   *
   * After the transaction the total supply will get updated
   */
  const buyNFT = async () => {
    setIsLoading(true)
    setLoaderDialogState(true)
    setIsMinting(true)
    setLoadingText([
      'Creating transaction',
      'Track your wallet for further details and progression.',
    ])
    const amountOfTokens = BN(value).toNumber()
    const func = await originsContract.methods
      .mintOrigins(amountOfTokens)
      .encodeABI()
    await sendTransaction(
      ethereum,
      account,
      getToken('ORIGINS').address,
      func,
      buyAmount,
      async (tx: any) => {
        setLoadingText([
          'Retrieving Origins',
          'We are almost there. Any moment now...',
        ])
        let arr: any = []
        if (account) {
          for (let i = 0; i < tx.logs.length; i++) {
            const indexInHex = tx.logs[i].topics[3];
            const number = web3.utils.hexToNumber(indexInHex);
            const baseURL = await originsContract.methods
              .tokenURI(number)
              .call()
            const res = await fetch(baseURL, { method: 'GET' })
            const json = await res.json()
            json.tokenID = number
            arr.push(json)
            if (arr.length === BN(value).toNumber()) {
              setIsViewingCollection(false)
              setIsLoading(false)
              setLoaderDialogState(false)
              setShowcaseNFTS(arr)
              setRegenDialogState(true)
              setCanViewCollection(false)
            }
          }
        }
      },
      (data: any) => {
        if (data.code === 4001) {
          setLoadingText(['Transaction signature denied', 'Try again...'])
        } else {
          setLoadingText(['Transaction failed', 'Try again...'])
        }
        setTimeout(() => {
          setIsLoading(false)
          setLoaderDialogState(false)
        }, 2000)
      },
    )
  }

  /**
   * @dev Get the current minted supply
   */
  const getTotalSupply = async () => {
    const totalSupply = await originsContract.methods.totalSupply().call()
    setTotalSupply(BN(totalSupply).toNumber())
  }

  /**
   * @dev Get the max supply that can be minted
   */
  const getMaxTotalSupply = async () => {
    const MAX_TOTAL_SUPPLY = await originsContract.methods.TOTAL_SUPPLY().call()
    setMaxTotalSupply(BN(MAX_TOTAL_SUPPLY).toNumber())
  }

  /**
   * @dev Disable the total supply based on the max total supply and the current total supply
   */
  const disableBuyOnTotalSupply = useCallback(() => {
    if (greaterThanOrEqual(totalSupply, maxTotalSupply))
      setAllTokensAreBought(true)
    else setAllTokensAreBought(false)
  }, [totalSupply, maxTotalSupply])

  /**
   * @dev Check if the current value is a float
   * @param N Could be a string | number | float
   */
  const isFloat = (n: any) => {
    return (
      !isNaN(n) &&
      (function (x) {
        return (x | 0) === x
      })(parseFloat(n))
    )
  }

  /**
   * @dev Get your bought NFT's based on etherscan api transactions
   */
  const getBoughtNFTS = useCallback(async () => {
    if (account !== null && !isMinting) {
      setCanViewCollection(false)
      setShowcaseNFTSIsLoading(true)
      const transactions = await getAccountTransactionsBasedOnContract(account)
      const result = transactions.result
      let arr: Array<any> = []
      for (let i = 0; i < result.length; i++) {
        // Get Bought Token ID's
        const tokenID = result[i].tokenID
        const baseURL = await originsContract.methods.tokenURI(tokenID).call()
        const res = await fetch(baseURL, { method: 'GET' })
        const json = await res.json()
        if (json) {
          json.tokenID = tokenID
          arr.push(json)
        }
      }
      if (arr) {
        setBoughtNFTS(arr)
        setCanViewCollection(true)
        setShowcaseNFTSIsLoading(false)
      }
    }
  }, [account, isMinting])

  /**
   * @dev Enables the button for people to mint
   * @note Move this maybe to a component because now the whole page get's rerendered every second because of the interval.
   */
  const enableMintButton = () => {
    if (!mintIntervalStarted) {
      setMintIntervalStarted(true);

      setInterval(() => {
        // get starting time in UTC
        const startDate = moment.utc(START_MINT_TIME);
        // get local time UTC string
        const strUTClocal:string = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        // convert the local UTC time string to a moment object
        const localDate = moment.utc(strUTClocal);
        // check if the current time in UTC is the same or after the starting time
        const mintStarted = localDate.isSameOrAfter(startDate);
        // if the mint has started (mintStarted == true) then enable the mint button
        setMintBtnDisabled(!mintStarted);
      }, 1000)
    }
  }

  useEffect(() => {
    getTotalSupply()
    getMaxTotalSupply()
    getPricePerNFT()
    getMaxTokensPerTX()
    enableMintButton();
  }, [])

  useEffect(() => {
    disableBuyOnTotalSupply()
  }, [disableBuyOnTotalSupply])

  useEffect(() => {
    getBoughtNFTS()
  }, [getBoughtNFTS])

  return (
    <>
      <div id="main-wrapper">
        <Menu
          onClickCollection={() => {
            setRegenDialogState(!regenDialogState)
            setIsViewingCollection(true)
          }}
          canViewCollection={canViewCollection}
        />
        <div id="bg-wrapper"></div>
        <div id="banner-wrapper">
          <div id="banner-main">
            <div className="banner-main-wrap">
              <div className="container">
                <div className="banner-blk-img">
                  <div className="logo-main carnage">
                    <img src={carnagelogo} alt="Black Market Gaming" />
                  </div>
                  <div className="cards-animation">
                    <div className="card-wrap card-1">
                      <img src={card1} alt="Black Market Gaming" />
                    </div>
                    <div className="card-wrap card-2">
                      <img src={card2} alt="Black Market Gaming" />
                    </div>
                    <div className="card-wrap card-3">
                      <img src={card3} alt="Black Market Gaming" />
                    </div>
                    <div className="card-wrap card-4">
                      <img src={card4} alt="Black Market Gaming" />
                    </div>
                    <div className="card-wrap card-5">
                      <img src={card5} alt="Black Market Gaming" />
                    </div>
                  </div>
                  <div className="banner-mobile-txt">
                    <div className="banner-header">
                      <h1>Mint your Carnage: Origins</h1>
                    </div>
                    <div className="banner-main-btm">
                      <p>
                      Mint your very own limited Carnage: Origins trading cards! A unique set designed to jumpstart the Carnage Trading Card Game and help bring our "Play to earn NFT"-experience to life!
                      </p>
                      <div className="banner-main-price-block">
                        <div className="banner-main-price-icon">
                          <img src={iconEtheriumlight} alt="Etherium" />
                        </div>
                        <div className="banner-main-price">
                          <div>{showAmount}</div>
                          <span>ETH</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="progress-detailed">
                    <div>View your Carnage: Origins on</div>
                    <p
                      onClick={() => setTextDialogState(true)}
                      className="progress-read-more"
                    ></p>
                    <div className="progress-buttons">
                      <div className="progress-button">
                        <a
                          href="https://opensea.io/account"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>
                            <img src={iconOpenSea} alt="OpenSea" />
                          </span>
                          <div>OpenSea</div>
                        </a>
                      </div>
                      <div className="progress-button">
                        <a
                          href="https://rarible.com/items?tab=owned"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>
                            <img src={iconRarible} alt="OpenSea" />
                          </span>
                          <div>Rarible</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="banner-text">
                  <div className="banner-text-main">
                    <div className="banner-header">
                      <h1>Mint your Carnage: Origins</h1>
                    </div>
                    <div className="banner-main-btm">
                      <div className="banner-main-txt">
                        <p>
                        Mint your very own limited Carnage: Origins trading cards! A unique set designed to jumpstart the Carnage Trading Card Game and help bring our "Play to earn NFT"-experience to life!
                        </p>
                        <div className="banner-main-price-block">
                          <div className="banner-main-price-icon">
                            <img src={iconEtherium} alt="Etherium" />
                          </div>
                          <div className="banner-main-price">
                            <div>{showAmount}</div>
                            <span>ETH</span>
                          </div>
                        </div>
                      </div>
                      <div className="banner-main-input">
                        <span>Amount</span>
                        <div
                          className="banner-main-input-buttons"
                          id="banner-main-input-btm"
                        >
                          <button
                            className="button l-btn sm"
                            onClick={() => setValue(5)}
                          >
                            <span>5</span>
                          </button>
                          <button
                            className="button l-btn sm"
                            onClick={() => setValue(10)}
                          >
                            <span>10</span>
                          </button>
                          <button
                            className="button l-btn sm"
                            onClick={() => setValue(15)}
                          >
                            <span>15</span>
                          </button>
                          <button
                            className="button l-btn sm"
                            onClick={() => setValue(20)}
                          >
                            <span>20</span>
                          </button>
                          <button
                            className="button l-btn sm"
                            onClick={() => setValue(25)}
                          >
                            <span>25</span>
                          </button>
                          <button
                            className="button l-btn sm"
                            onClick={() => setValue(30)}
                          >
                            <span>30</span>
                          </button>
                        </div>
                        <div
                          className="banner-main-input-top"
                          id="banner-main-input-top"
                        >
                          <input
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                            type="number"
                            pattern="[0-9]+"
                          />
                          {window.innerWidth <= 768 ? (
                            <button
                              id="mint-btn"
                              className={`button btn-main`}
                              style={{ minWidth: 100 }}>
                              <span>No mobile support</span>
                            </button>
                          ) : (
                            <button
                              id="mint-btn"
                              style={{ minWidth: 100 }}
                              disabled={
                                greaterThan(value, maxTokensPerTransaction) ||
                                !isFloat(value) ||
                                equalTo(value, 0) ||
                                allTokensAreBought ||
                                !account ||
                                mintBtnDisabled ||
                                true
                              }
                              className={`button btn-main ${
                                isLoading && 'loading'
                              }`}
                              onClick={() => buyNFT()}>
                              <span>Mint Origins</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NFTShowcase
          isLoading={showcaseNFTSIsLoading}
          isDebug={NFTShowcaseDebug}
          canViewCollection={canViewCollection}
          nfts={
            showcaseNFTS.length > 0 && isMinting ? showcaseNFTS : boughtNFTS
          }
          onClose={() => setRegenDialogState(false)}
          open={regenDialogState}
          isViewingCollection={isViewingCollection}
        />
        <LoaderDialog
          open={loaderDialogState}
          onClose={() => setLoaderDialogState(false)}
          title={loadingText[0]}
          subtitle={loadingText[1]}
        />
        <TextDialog
          open={textDialogState}
          onClose={() => setTextDialogState(false)}
          price={BN(pricePerToken).toNumber()}
          maxBuy={BN(maxTokensPerTransaction).toNumber()}
          maxTotalSupply={maxTotalSupply}
        />
      </div>
    </>
  )
}

export default Home

import { Connectors } from "use-wallet";

export const networkId = parseInt(process.env.REACT_APP_CHAIN_ID!.toString());
export const web3Provider = networkId === 1
  ? process.env.REACT_APP_MAIN_WEB3_PROVIDER
  : networkId === 4 ? process.env.REACT_APP_RINKEBY_WEB3_PROVIDER : process.env.REACT_APP_KOVAN_WEB3_PROVIDER

export const etherAPI = networkId === 1
  ? process.env.REACT_APP_MAIN_ETHER_API
  : networkId === 4 ? process.env.REACT_APP_RINKEBY_ETHER_API : process.env.REACT_APP_KOVAN_ETHER_API

export const config = {
  web3Provider: web3Provider,
  networkId: networkId
};

export const getOpenSeaLink = () => {
  return networkId === 1 ? process.env.REACT_APP_OPENSEA : process.env.REACT_APP_TESTNET_OPENSEA;
}

export function setWalletConnected(value: keyof Connectors | null) {
  localStorage.setItem('__WALLET_CONNECTED', JSON.stringify(value));
}

export function isWalletConnected(): keyof Connectors | null {
  const val = localStorage.getItem('__WALLET_CONNECTED');

  return val ? JSON.parse(val) : null;
}


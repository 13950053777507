import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import RingLoader from 'react-spinners/RingLoader'

interface Props {
	open: boolean
	onClose: Function
	title: string,
	subtitle: string
}

function LoaderDialog(props: Props) {

	useEffect(() => { }, [props.title])

	return (
		<Dialog
			open={props.open}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					
					return;
				}
			}}
			// disableBackdropClick={false}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className='loader-dialog'>
			<DialogContent>
				<div className="alert-dialog-loading">
					<div className="alert-dialog-loading-icon">
						<RingLoader />
					</div>
					<div className="alert-dialog-loading-title">
						{props.title}<span>...</span>
					</div>
					<div className='subtitle-loader'>
						{props.subtitle}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default LoaderDialog
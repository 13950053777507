import { etherAPI } from "../utils/config";
import { getToken } from "../utils/contracts";
import _ from 'lodash';

export const getAccountTransactionsBasedOnContract = async (addr: string) => {
    try {
        const baseUrl = `${etherAPI}/api?module=account&action=tokennfttx&contractaddress=${getToken('ORIGINS').address}&address=${addr}&page=1&offset=1000&sort=desc&apikey=${process.env.REACT_APP_API_KEY}`;
        const res = await fetch(baseUrl, { method: "GET" });
        const json = await res.json();
        const duplicateTokenIDS: Array<any> = [];

        // Finding the duplicate token ID's and pushing them into the duplicate array
        let duplicatesArr = _.difference(json.result, _.uniqBy(json.result, 'tokenID'), 'json.result');
        duplicatesArr.forEach((element: any) => {
            duplicateTokenIDS.push(element.tokenID);
        });

        // Removing that one
        let newResult: Array<any> = [];
        json.result.forEach((res: any) => {
            duplicateTokenIDS.forEach((duplicateID: any) => {
                if(res.tokenID !== duplicateID) {
                    newResult.push(res);
                }
            });    
        });
        
        if(newResult.length > 0) {
            json.result = newResult
        }
        return json;
    } catch {
        return {}
    }
}

import React, { useEffect, useCallback, useState } from 'react'
import logo from '../../assets/images/BMG-Logo-DEF-02.svg'

import { useWallet } from 'use-wallet'
import { truncateFromCenter } from '../../helpers/sharedFunctions'
import {
  config,
  isWalletConnected,
  setWalletConnected,
} from '../../utils/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icons } from '../../helpers/icons'
import { originsContract } from '../../utils/contracts'
import { greaterThan } from '../../helpers/bignumber'

interface Props {
  onClickCollection: Function
  canViewCollection: boolean
}

function Menu(props: Props) {
  const wallet = useWallet()
  const connectedId = isWalletConnected()
  const [btnText, setBtnText] = useState('Connect')
  const [viewCollection, setViewCollection] = useState(false)
  const [balance, setBalance] = useState(0)

  /**
   * @dev Connect wallet to metamask
   */
  const connectToWallet = () => {
    setViewCollection(!viewCollection)
    if (wallet.status === 'disconnected') {
      wallet.connect('injected')
      setWalletConnected('injected')
    }
  }

  /**
   * @dev Checking if you connected before if yes reconnect.
   */
  const checkConnection = () => {
    if (wallet.status === 'disconnected' && connectedId != null) {
      wallet.connect(connectedId)
    }
  }

  /**
   * @dev Based on the connected chain check if you need to show the wallet address or the wrong network txt
   */
  const getConnectButtonTxt = useCallback(async () => {
    // 0x1 = Mainnet
    // 0x4 = Rinkeby
    // 0x2a = Kovan
    const id =
      config.networkId === 1 ? '0x1' : config.networkId === 4 ? '0x4' : '0x2a'
    if (window.ethereum) {
      window.ethereum.on('chainChanged', (changedChainID: any) => {
        if (changedChainID === id) {
          window.location.reload()
        } else if (changedChainID !== id) return setBtnText('Wrong network')
        else setBtnText('Connect')
      })

      const chainId = await window.ethereum.request({ method: 'eth_chainId' })
      if (chainId === id && wallet && wallet.account)
        return setBtnText(truncateFromCenter(wallet.account))
      if (chainId !== id) setBtnText('Wrong network')
      else setBtnText('Connect')
    }
  }, [wallet])

  /**
   * @dev Getting your balance
   */
  const getNFTBalance = useCallback(async () => {
    if (wallet.account) {
      const bal = await originsContract.methods.balanceOf(wallet.account).call()
      setBalance(bal)
    }
  }, [wallet.account])

  const openQuickswap = () => {
    window.open(
      'https://quickswap.exchange/#/swap?outputCurrency=0xc7d5a5df6ae030520366ef8a300543941b99e843',
      '_blank',
    )
  }

  useEffect(() => {
    checkConnection()
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getNFTBalance()
  }, [getNFTBalance])

  useEffect(() => {
    getConnectButtonTxt()
  }, [getConnectButtonTxt])

  return (
    <>
      <div id="navbar">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="nav-toggle nav-trigger">
              <div className="nav-icon">
                <span></span>
              </div>
            </button>
            <a className="logo-main" href="/">
              <span className="logo-main-light">
                <img src={logo} alt="BMG" />
              </span>
            </a>
            <div className="nav-wrap">
              <nav id="nav-main" className="nav-full">
                <ul>
                  <li>
                    <a
                      className=""
                      href="https://www.bmgaming.io/forum/tags"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Forum
                    </a>
                  </li>
                  <li>
                    <a
                      className=""
                      href="https://www.bmgaming.io/forum/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Categories
                    </a>
                  </li>
                </ul>
              </nav>
              <nav id="nav-right">
                <button className="button" onClick={() => openQuickswap()}>
                  <span>Trade $BMG</span>
                </button>
                {wallet && (
                  <button
                    className="button"
                    onClick={() => props.onClickCollection(true)}
                  >
                    <span>View your collection</span>
                  </button>
                )}
                {window.innerWidth <= 768 ? (
                  <></>
                ) : (
                  <button
                    className="button l-btn"
                    onClick={() => connectToWallet()}
                  >
                    <span>{btnText}</span>
                  </button>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu

import ORIGINS_ABI from '../assets/abi/origins.json'

export const addresses: any = {
    'ORIGINS': {
        1: {
            'address': '0xD4Ac7e751C2B9A5a0F45B9c1eead829cf691711A',
            'abi': ORIGINS_ABI
        },
        4: {
            'address': '0xEdD8b5bbDdF808455271D34ABda9BD484f6a9Aa2',
            'abi': ORIGINS_ABI
        },
        42: {
            'address': '',
            'abi': ORIGINS_ABI
        },
    }
}